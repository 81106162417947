import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import moment from 'moment';

const LISTADO_SALIDA = 'LISTADO_SALIDA';
const GUARDAR_SALIDA = 'GUARDAR_SALIDA';
const LOADER = 'LOADER';
const MODULO = 'MODULO';
const PERIODO_D = 'PERIODO_D';
const DETALLE_PRODUCTOS = 'DETALLE_PRODUCTOS';
const PARAMETRO = 'PARAMETRO';
const PRODUCTOS = 'PRODUCTOS';
const DETALLE = 'DETALLE';

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setLoaderC = loader => ({
    type: LOADER,
    loader,
});


export const listar = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let periodo = id_emp[7];
    let motivo = 'SAL-PRD-C'
    //const params = { page, id, periodo, motivo };
    const params = { id, periodo, motivo };
    api.get('/movimiento_inventario', params).then((response)=>{
        dispatch({ type: LISTADO_SALIDA, data: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al listar consumos',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

export const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`/movimiento_inventario/${id}`).then((response) => {
        dispatch({type: GUARDAR_SALIDA, lectura: response });
        localStorage.setItem('id_detalle_consumo_prd', response.id);
        dispatch(initializeForm('FormConsumoProduccion', response ));
        dispatch(leerDetalleProductos(response.id));
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar la salida',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const getModulo = (id, modulo) => (dispatch) => {
    const params = {id, modulo}
    dispatch(setLoader(true));
    api.get('/modulos/modulo/', params).then((response)=>{
        dispatch({ type: MODULO, modulo: response.modulos });
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en buscar modulo',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const leerPeriodoD = (id, id_periodo) => (dispatch) => {
    const params = { id, id_periodo };
    api.get('/periodos/periodo', params).then((response) => {
        dispatch({ type: PERIODO_D, periodoD: response.periodo });
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar periodo',
            'Error',
            2000
        );
    })
}

export const registroConsumo = (periodoD, numeroSerieSal) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConsumoProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    
    if (fecha>=fechaI && fecha<=fechaF){
        
        const formData = {
            fecha: data.fecha,
            serie: data.serie.label,
            numero: numeroSerieSal,
            descripcion: data.descripcion,
            detalleCon: data.detalleD,
            tipoDoc: 'CON',
            motivo: 'SAL-PRD-C',
            empresa: id_emp[5],
            periodo: id_emp[7]
        }
        dispatch(setLoader(true));
        api.post('/movimiento_inventario/', formData).then(() => {
            NotificationManager.success(
                'Consumo guardado correctamente',
                'Exito',
                3000
            );

            let ruta = `/produccion/${id_emp[5]}/consumo/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                'Consumo ya existe',
                'Error',
                2000
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Consumo',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

const leerDetalleProductos = id => (dispatch) => {
    api.get(`/movimiento_consumo_prd/${id}`).then((response)=>{
        dispatch({ type: DETALLE_PRODUCTOS, detalleProducto: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error',
            'Error',
            0
        );
    })
};

const eliminarLineaProducto = id => (dispatch) => {
    const detalle = localStorage.getItem('id_detalle_consumo_prd');
    dispatch(setLoader(true));
    api.eliminar(`/movimiento_consumo_prd/${id}`).then(() => {
        dispatch(leerDetalleProductos(detalle));
    }).catch(() => {
        NotificationManager.error(
            'Ocurrió un error en eliminar',
            'Error',
            0
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const confirmarSalida = () => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    if (localStorage.getItem('id_detalle_consumo_prd')) {
        localStorage.removeItem('id_detalle_consumo_prd');
        NotificationManager.success(
            'Salida de inventario guardada correctamente',
            'Exito',
            3000
        );
        const editar = window.location.href.includes('editar');
        if (editar){
            ruta = `/produccion/${id_emp[5]}/consumo/${id_emp[7]}`
            dispatch(push(ruta));
        }else{
            window.location.reload(true);
        }
        
    }else {
        Swal.fire({
            title: 'Consumo',
            text: 'No hay productos ingresados',
            type: 'error',
        })
    }
}

export const parametrosEmpresa = () => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = {id}
    dispatch(setLoader(true));
    api.get('/empresa/empresa_parametros', params).then((response) => {
        dispatch({type: PARAMETRO, datos: response });
    }).catch((error)=>{
        NotificationManager.error(
            'Ocurrió un error al consultar parametros',
            'Error',
            8000
        );
    }).finally(() => {
        dispatch(setLoader(false));
    });

}

export const listarBodegas = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search };
    return api.get('/bodega', params).then((response) => {
        const bodega = [];
        if (response){
            response.forEach(item=>{
                bodega.push({
                    value: item.id,
                    label: item.numero + ' / ' + item.bodega
                })
            })
        }
        return bodega;
    }).catch((error) => {
        return [];
    })
}

export const leerProductos = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    const params = {id, idEmpre }
    api.get('/producto/empresa_productos_cod_inventario_bodega_prd/',params).then((response) => {
        const producto = [];
        response.forEach(item=>{
            if (item.tipoPro === 'P'){
                producto.push({
                    value: item.id+' # '+ item.saldo + '#' + item.tipoPro,
                    label: item.sku + ' / ' + item.productoN + ' -- ' + ' Existencia: ' + new Intl.NumberFormat("en-US", { minimumFractionDigits: 6 }).format(item.saldo)
                })
            }
        })
        dispatch({type: PRODUCTOS, pro: producto })
    })
}

export const listarSeries = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    let tipo = 'CON';
    const params = { id, search, tipo }
    return api.get('/serie_prd', params).then(data => {
        const list_serie = [];
        if (data) {
            data.forEach(item => {
                list_serie.push({
                    value: item.id,
                    label: item.serie
                })
            })
        }
        return list_serie;
    }).catch((error) => {
        return [];
    })
}

export const numeroSerie = (id) => (dispatch) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let idEmpre = id_emp[5];
    let tipo = 'CON';
    const params = { id, idEmpre, tipo }
    dispatch(setLoaderC(true));
    return api.get('/serie_prd/serie/', params).then((response) => {
        return response;
    }).catch((error) => {
        NotificationManager.error(
            'Ocurrió un error al consultar numero de serie',
            'Error',
            2000
        );
    }).finally(() => {
        dispatch(setLoaderC(false));
    });

}

export const listarFases = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/fase_prd/buscar_fase', params).then(data => {
        const list_fase = [];
        if (data) {
            data.forEach(item => {
                list_fase.push({
                    value: item.id,
                    label: item.descripcion
                })
            })
        }
        return list_fase;
    }).catch((error) => {
        return [];
    })
}

let detalle = [];
export const agregarLinea = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProduccion.values;
    
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.bodega === undefined || data.bodega === 0.00) {
            Swal.fire({
                title: 'Consumo',
                text: 'Debe de seleccionar bodega',
                type: 'error',
            })
        } else {
            if (data.producto === undefined || data.producto === null) {
                Swal.fire({
                    title: 'Consumo',
                    text: 'Debe de seleccionar producto',
                    type: 'error',
                })
            } else {
                if (data.cantidad === undefined || data.cantidad === 0.000000) {
                    Swal.fire({
                        title: 'Consumo',
                        text: 'Debe de ingresar cantidad',
                        type: 'error',
                    })
                } else {
                    if (data.fase === undefined || data.fase === null) {
                        Swal.fire({
                            title: 'Consumo',
                            text: 'Debe de seleccionar llave',
                            type: 'error',
                        })
                    } else {
                        if (data.piloto === undefined || data.piloto === null) {
                            Swal.fire({
                                title: 'Consumo',
                                text: 'Debe de seleccionar solicitante',
                                type: 'error',
                            })
                        } else {
                            let proN = data.producto.label.split("--");
                            let proID = proN[0].split("/");
                            let pro = data.producto.value.split('#');
                            
                            if (parseFloat(data.cantidad) > parseFloat(pro[1].trim())){
                                Swal.fire({
                                    title: 'Consumo',
                                    text: 'Cantidad ingresada es mayor a la existencia',
                                    type: 'error',
                                })
                            }else {
                                detalle.push({
                                    id: pro[0].trim(),
                                    cantidad: data.cantidad,
                                    idPro: pro[0].trim(),
                                    sku: proID[0].trim(),
                                    producto: proID[1].trim(),
                                    fase: data.fase.label,
                                    idFase: data.fase.value,
                                    idBodega: data.bodega.value, 
                                    bodega: data.bodega.label,
                                    solicitante: data.piloto.value
                                })
                                data.cantidad = 0;
                                data.detalleD = detalle;
                                NotificationManager.success(
                                    'Producto ingresado correctamente',
                                    'Exito',
                                    3000
                                );
                                dispatch(actualizar());
                            }
                        }
                    }
                }
            }
        }

    } else {
        Swal.fire({
            title: 'Consumo',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

const actualizar = () => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProduccion.values;
    dispatch(initializeForm('FormConsumoProduccion', data));
    dispatch({ type: DETALLE, detalleCon: data.detalleD })
};

const vaciar = () => (dispatch) => {
    detalle = [];
    dispatch(initializeForm('FormConsumoProduccion'));
    dispatch({ type: DETALLE, detalleCon: detalle });
};

export const listarPilotos = (search) => () => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    let id = id_emp[5];
    const params = { id, search }
    return api.get('/piloto/buscar_piloto', params).then(data => {
        const list_ing = [];
        if (data) {
            data.forEach(item => {
                list_ing.push({
                    value: item.id,
                    label: item.nombre
                })
            })
        }
        return list_ing;
    }).catch((error) => {
        return [];
    })
}

export const nuevaLineaInventario = (periodoD) => (dispatch, getStore) => {
    const data = getStore().form.FormConsumoProduccion.values;
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const detalle = localStorage.getItem('id_detalle_consumo_prd');

    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha);
    
    if (fecha >= fechaI && fecha <= fechaF) {
        if (data.bodega === undefined || data.bodega === 0.00) {
            Swal.fire({
                title: 'Consumo',
                text: 'Debe de seleccionar bodega',
                type: 'error',
            })
        } else {
            if (data.producto === undefined || data.producto === null) {
                Swal.fire({
                    title: 'Consumo',
                    text: 'Debe de seleccionar producto',
                    type: 'error',
                })
            } else {
                if (data.cantidad === undefined || data.cantidad === 0.000000) {
                    Swal.fire({
                        title: 'Consumo',
                        text: 'Debe de ingresar cantidad',
                        type: 'error',
                    })
                } else {
                    if (data.fase === undefined || data.fase === null) {
                        Swal.fire({
                            title: 'Consumo',
                            text: 'Debe de seleccionar llave',
                            type: 'error',
                        })
                    } else {
                        if (data.piloto === undefined || data.piloto === null) {
                            Swal.fire({
                                title: 'Consumo',
                                text: 'Debe de seleccionar solicitante',
                                type: 'error',
                            })
                        } else {
                            let proN = data.producto.label.split("--");
                            let proID = proN[0].split("/");
                            let pro = data.producto.value.split('#');
                            
                            if (parseFloat(data.cantidad) > parseFloat(pro[1].trim())){
                                Swal.fire({
                                    title: 'Consumo',
                                    text: 'Cantidad ingresada es mayor a la existencia',
                                    type: 'error',
                                })
                            }else {
                                const formData = {
                                    cantidad: data.cantidad,
                                    idBodega: data.bodega.value,
                                    idPro: pro[0].trim(),
                                    idFase: data.fase.value,
                                    solicitante: data.piloto.value,
                                    detalle: detalle,
                                    tipoDoc: 'CON',
                                    empresa: id_emp[5],
                                    periodo: id_emp[7]
                                }
                                dispatch(setLoader(true));
                                api.post('/movimiento_inventario/', formData).then((response) => {
                                    response.serie = response.encabezado.serie;
                                    response.numero = response.encabezado.numero;
                                    response.fecha = response.encabezado.fecha;
                                    response.descripcion = response.encabezado.descripcion;
                                    dispatch(initializeForm('FormConsumoProduccion', response));
                                    dispatch(leerDetalleProductos(response.encabezado.id));
                                    NotificationManager.success(
                                        'Producto ingresado correctamente',
                                        'Exito',
                                        3000
                                    );
                                }).catch((error) => {
                                    NotificationManager.error(
                                        error.msj,
                                        'Error',
                                        0
                                    );
                                }).finally(() => {
                                    dispatch(setLoader(false));
                                });
                            }
                        }
                    }
                }
            }
        }

    } else {
        Swal.fire({
            title: 'Consumo',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })
    }
}

export const modificarConsumo = (periodoD) => (dispatch, getStore) => {
    let ruta = window.location.href;
    let id_emp = ruta.split('/');
    const data = getStore().form.FormConsumoProduccion.values;
    let fechaI = moment(periodoD.fecha_inicio);
    let fechaF = moment(periodoD.fecha_fin);
    let fecha = moment(data.fecha)
    
    let id = data.id
    if (fecha>=fechaI && fecha<=fechaF){
        
        const formData = {
            id: id,
            fecha: data.fecha,
            descripcion: data.descripcion,
            tipoDoc: 'CON',
        }
        dispatch(setLoader(true));
        api.put(`/movimiento_inventario/${id}/`, formData).then(() => {
            NotificationManager.success(
                'Consumo modificado correctamente',
                'Exito',
                3000
            );

            let ruta = `/produccion/${id_emp[5]}/consumo/${id_emp[7]}`
            dispatch(push(ruta));
        }).catch((error) => {
            NotificationManager.error(
                error.msj,
                'Error',
                0
            );
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }else {
        Swal.fire({
            title: 'Consumo',
            text: 'Fecha fuera del periodo',
            type: 'error',
        })        
    }
}

export const actions = {
    listar,
    leer,
    getModulo,
    leerPeriodoD,
    registroConsumo,
    leerDetalleProductos,
    eliminarLineaProducto,
    modificarConsumo,
    parametrosEmpresa,
    listarBodegas,
    leerProductos,
    listarSeries,
    numeroSerie,
    listarFases,
    agregarLinea,
    actualizar,
    listarPilotos,
    nuevaLineaInventario,
    vaciar
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LISTADO_SALIDA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GUARDAR_SALIDA]: (state, { lectura }) => {
        return {
            ...state,
            lectura,
        };
    },
    [MODULO]: (state, { modulo }) => {
        return {
            ...state,
            modulo,
        };
    },
    [PERIODO_D]: (state, { periodoD }) => {
        return {
            ...state,
            periodoD,
        };
    },
    [DETALLE_PRODUCTOS]: (state, { detalleProducto }) => {
        return {
            ...state,
            detalleProducto,
        };
    },
    [PARAMETRO]: (state, { datos }) => {
        return {
            ...state,
            datos,
        };
    },
    [PRODUCTOS]: (state, { pro }) => {
        return {
            ...state,
            pro,
        };
    },
    [DETALLE]: (state, { detalleCon }) => {
        return {
            ...state,
            detalleCon,
        };
    },
};

export const initialState = {
    loader: false,
    lectura: [],
    modulo: [],
    periodoD: [],
    datos: [],
    pro: [],
    detalleCon: [],
    detalleProducto: [],
    data: [],
    page: 1,
};

export default handleActions(reducers, initialState)
